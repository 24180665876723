import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './shared/components/layout/Layout';
import GamesNonAuthRoutes from './games/routes/Games.NonAuth.Routes';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route Component={Layout}>
            <Route path="*" element={<>Main route page</>}></Route>
            <Route path="games/*" element={<GamesNonAuthRoutes />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
