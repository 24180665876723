import GameList from '../components/GameList';

export default function GamesPage() {
  return (
    <div
      className="p-3 bg-light text-dark"
      style={{
        width: '1000px',
      }}
    >
      <h1>Games</h1>
      <GameList />
    </div>
  );
}
