export default function LeftSideBar() {
  return (
    <nav id="sidebarMenu" className="d-md-block bg-light sidebar collapse vh-100">
      <div className="position-sticky">
        <h3 className="p-3 mx-4">CYBER GAMING</h3>
        {/* <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">
              Панель управления
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Настройки
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Помощь
            </a>
          </li>
        </ul> */}
      </div>
    </nav>
  );
}
