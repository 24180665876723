import { Route, Routes } from 'react-router-dom';
import GamesPage from '../pages/Games.Page';
import Game from '../components/Game';

export default function GamesNonAuthRoutes() {
  return (
    <Routes>
      <Route path="*" element={<GamesPage />}></Route>
      <Route path="/list" element={<>Game list</>}></Route>
      <Route path="/view/:gameUrl" element={<Game />}></Route>
      <Route path="/sort/" element={<>Games filters</>}></Route>
      <Route path="/genre/:genreUrl" element={<>games by genre</>}></Route>
    </Routes>
  );
}
