import { useEffect, useState } from 'react';
import { GameDTO } from '../../shared/interfaces/game/Game.DTO';
import api from '../../shared/services/Api';
import { Link } from 'react-router-dom';

export default function GameList() {
  const [games, setGames] = useState<GameDTO[] | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get('/api/GamesCommand/GetGames');
      setGames((await response).data);
    };
    fetchData();
  }, []);

  if (!games) {
    return <>no data</>;
  }

  return (
    <>
      {games.map((game) => (
        <div key={game.id}>
          <Link to={`/games/view/${game.url}`}> {game.name}</Link>
        </div>
      ))}
    </>
  );
}
