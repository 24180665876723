import { useEffect, useState } from 'react';
import { GameDTO } from '../../shared/interfaces/game/Game.DTO';
import api from '../../shared/services/Api';
import { useParams } from 'react-router-dom';

export default function Game() {
  const [game, setGame] = useState<GameDTO | null>(null);
  const { gameUrl } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const response = await api.get(`/api/GamesCommand/GetGameByUrl?url=${gameUrl}`);
      setGame(response.data);
    };
    fetchData();
  }, []);

  if (!game) {
    return <>no data</>;
  }

  return (
    <>
      {' '}
      <div
        className="p-3 bg-light text-dark"
        style={{
          width: '1000px',
        }}
      >
        <h1>{game.name}</h1>
        <div>{game.description}</div>
      </div>
    </>
  );
}
