import { Outlet } from 'react-router-dom';
import TopBar from './header/TopBar';
import LeftSideBar from './sidebars/LeftSideBar';
import Footer from './footer/Footer';

export default function Layout() {
  return (
    <div>
      <div></div>

      <div className="row m-0 p-0">
        <div className="col-2 m-0 p-0">
          <LeftSideBar />
        </div>
        <div className="col m-0 p-0">
          <div className="d-flex flex-column min-vh-100">
            {/* Первый блок */}
            <div className="p-0 bg-primary text-white">
              <TopBar />
            </div>

            {/* Второй блок */}
            <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
              <Outlet />
            </div>

            {/* Третий блок (прижат к низу) */}
            <div className="mt-auto p-0">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
